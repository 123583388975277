// TravelCard.tsx
import React from 'react';
import '@sbb-esta/lyne-elements/standard-theme.css';
import {SbbCard} from "@sbb-esta/lyne-react/card";
import {SbbTitle} from "@sbb-esta/lyne-react/title";

export interface TravelCardProps {
    title: string;
    benefit: string;
    bestFor: string;
    keyConsideration: string;
}


const TravelCard: React.FC<TravelCardProps> = ({title, benefit, bestFor, keyConsideration}) => {
    return (
        <div>
            <SbbCard size="xs" color="transparent-bordered">
                <SbbTitle level="2">{title}</SbbTitle>
                <span className="sbb-text-m">
        <p className="travel-card-benefit"><strong>Benefit:</strong> {benefit}</p>
        <p className="travel-card-best-for"><strong>Best For:</strong> {bestFor}</p>
        <p className="travel-card-key-consideration"><strong>Key Consideration:</strong> {keyConsideration}</p>
  </span>

            </SbbCard>
        </div>

    )
        ;
};

export default TravelCard;
