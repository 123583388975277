import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const MetaTags: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Helmet>
            {/* Basic Meta Tags */}
            <meta name="description" content={t('meta.description')} />
            <meta name="keywords" content={t('meta.keywords')} />
            <meta name="author" content={t('meta.author')} />

            {/* Open Graph Meta Tags */}
            <meta property="og:title" content={t('meta.ogTitle')} />
            <meta property="og:description" content={t('meta.ogDescription')} />
            <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
            <meta property="og:url" content="https://www.aboadvisor.ch" />
            <meta property="og:type" content="website" />

            {/* Twitter Card Meta Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={t('meta.twitterTitle')} />
            <meta name="twitter:description" content={t('meta.twitterDescription')} />
            <meta name="twitter:image" content="%PUBLIC_URL%/favicon.ico" />
            <meta name="twitter:url" content="https://www.aboadvisor.ch" />

            {/* Title */}
            <title>{t('meta.ogTitle')}</title>
        </Helmet>
    );
};

export default MetaTags;
