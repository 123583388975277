// HalfFareTravelcardPlus.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import TravelCard from './TravelCard';

const HalfFareTravelcardPlus: React.FC = () => {
    const { t } = useTranslation();

    return (
        <TravelCard
            title={t('travelCards.halfFareTravelcardPlus.title')}
            benefit={t('travelCards.halfFareTravelcardPlus.benefit')}
            bestFor={t('travelCards.halfFareTravelcardPlus.bestFor')}
            keyConsideration={t('travelCards.halfFareTravelcardPlus.keyConsideration')}
        />
    );
};

export default HalfFareTravelcardPlus;
