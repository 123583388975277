import React from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, useNavigate, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './components/LanguageSwitcher';
import ChatbotPanel from "./components/ChatbotPanel";
import MetaTags from "./components/MetaTags";
import { SbbContainer } from "@sbb-esta/lyne-react/container";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Navigate to="/en" replace />} />
                <Route path="/:lang" element={<LanguagePage />} />
            </Routes>
        </Router>
    );
};

const LanguagePage = () => {
    const { lang } = useParams();
    const { i18n } = useTranslation();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (lang && lang !== i18n.language) {
            i18n.changeLanguage(lang);
        }
    }, [lang, i18n]);

    const changeLanguage = (event: CustomEvent) => {
        const selectedLanguage = (event.target as HTMLSelectElement).value;
        i18n.changeLanguage(selectedLanguage);
        navigate(`/${selectedLanguage}`);
    };

    return (
        <SbbContainer>
            <LanguageSwitcher onChange={changeLanguage} currentLanguage={lang || 'en'} />
            <div>
                <MetaTags />
                <ChatbotPanel />
            </div>
        </SbbContainer>
    );
};

export default App;
