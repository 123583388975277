import React from 'react';
import {BinData} from "./types";
import {SbbTableWrapper} from "@sbb-esta/lyne-react/table";
interface CostDetailsProps {
    data: BinData;
}

const CostDetails: React.FC<CostDetailsProps> = ({ data }) => {
    const { label, value, computationalDetails } = data;
    const { list_of_abos, half_fare_card_details } = computationalDetails;

    console.log("Half Fare Card Cost Type:", typeof half_fare_card_details.cost);

    return (
        <SbbTableWrapper>
            <h2>Subscription Overview</h2>
            <table className="sbb-table">
                <thead>
                <tr>
                    <th>Label (Abo Combination)</th>
                    <th>Total Cost (CHF)</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{label}</td>
                    <td>{value.toFixed(2)}</td>
                </tr>
                </tbody>
            </table>

            <h3>Half Fare Card Details</h3>
            <table className="sbb-table">
                <thead>
                <tr>
                    <th>From</th>
                    <th>To</th>
                </tr>
                </thead>
                <tbody>
                {half_fare_card_details.hta_abo_periods.map((period, index) => (
                    <tr key={index}>
                        <td>{period.from}</td>
                        <td>{period.to}</td>
                    </tr>
                ))}
                <tr>
                    <td><strong>Total Cost</strong></td>
                    <td>{half_fare_card_details.cost.toFixed(2)}</td>
                </tr>
                </tbody>
            </table>

            <h3>List of Abos</h3>
            <table className="sbb-table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>From</th>
                    <th>End</th>
                    <th>Cost (CHF)</th>
                </tr>
                </thead>
                <tbody>
                {list_of_abos.map((abo, index) => (
                    <tr key={index}>
                        <td>{abo.name}</td>
                        <td>{abo.from_date}</td>
                        <td>{abo.end_date}</td>
                        <td>{abo.cost.toFixed(2)}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </SbbTableWrapper>
    );
};

export default CostDetails;
