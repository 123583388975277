import React from 'react';
import {BinData} from './types';
import {SbbTableWrapper} from "@sbb-esta/lyne-react/table";
import {SbbSecondaryButton, SbbTertiaryButton} from "@sbb-esta/lyne-react/button";

interface CostTableProps {
    bins: BinData[];
    setSelectedBin: (bin: BinData) => void;  // Function to set the selected bin
}

const CostTable: React.FC<CostTableProps> = ({bins, setSelectedBin}) => {
    return (
        <SbbTableWrapper>
            <table
                role="table"
                aria-label="Cost of different abo combinations"
                className="sbb-table">
                <caption style={{textAlign: 'left', fontSize: '1.25em', marginBottom: '0.5em'}}>
                    Cost of Different Abo Combinations
                </caption>
                <thead>
                <tr>
                    <th >Abo Combination</th>
                    <th >Cost</th>
                    <th >Details</th>
                </tr>
                </thead>
                <tbody>
                {bins.map((bin, index) => (
                    <tr
                        key={index}
                        onClick={() => setSelectedBin(bin)}  // Set selected bin when row is clicked
                        style={{
                            cursor: 'pointer'  // Indicate that the row is clickable
                        }}
                    >
                        <td
                            aria-label={`Abo combination: ${bin.label}`}
                        >
                            {bin.label}
                        </td>
                        <td
                            aria-label={`Cost: ${bin.value}`}
                        >
                            {bin.value.toFixed(2)}
                        </td>
                        <td
                        >
                            {bin.isBest ? (
                                <SbbSecondaryButton onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedBin(bin);
                                }}>
                                    View Details
                                </SbbSecondaryButton>
                            ) : (
                                <SbbTertiaryButton onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedBin(bin);
                                }}>
                                    View Details
                                </SbbTertiaryButton>
                            )}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </SbbTableWrapper>
    );
};

export default CostTable;
