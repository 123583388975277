// NoCard.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import TravelCard from './TravelCard';

const NoCard: React.FC = () => {
    const { t } = useTranslation();

    return (
        <TravelCard
            title={t('travelCards.noCard.title')}
            benefit={t('travelCards.noCard.benefit')}
            bestFor={t('travelCards.noCard.bestFor')}
            keyConsideration={t('travelCards.noCard.keyConsideration')}
        />
    );
};

export default NoCard;
