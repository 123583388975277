import React from 'react';
import { SbbSelect } from "@sbb-esta/lyne-react/select";
import { SbbOption } from "@sbb-esta/lyne-react/option";
import { SbbFormField } from "@sbb-esta/lyne-react/form-field";

interface LanguageSwitcherProps {
    onChange: (event: CustomEvent) => void;
    currentLanguage: string;
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ onChange, currentLanguage }) => {
    return (
        <div>
            <SbbFormField data-testid="form-field" size="m">
                <label htmlFor="language-select">Select your language:</label>
                <SbbSelect
                    id="language-select"
                    value={currentLanguage}
                    onDidChange={onChange} // Note: using onDidChange instead of onChange
                >
                    <SbbOption value="en">English</SbbOption>
                    <SbbOption value="de">Deutsch</SbbOption>
                    <SbbOption value="fr">Français</SbbOption>
                    <SbbOption value="it">Italiano</SbbOption>
                    <SbbOption value="rm">Rumantsch</SbbOption>
                </SbbSelect>
            </SbbFormField>
        </div>
    );
};

export default LanguageSwitcher;
